import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import WelcomeView from './components/Welcome';
import PickerView from './components/Picker';
import OrdersView from './components/Orders';
import OrderPrintView from './components/OrderPrint';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={WelcomeView} />
        <Route exact path="/buy" component={PickerView} />
        <Route exact path="/admin/orders" component={OrdersView} />
        <Route path="/admin/orders/print/:id" component={OrderPrintView} />
      </Switch>
    </Router>
  );
}

export default App;
