import React from 'react';
import { Link } from "react-router-dom";
import { UserInterface } from '../types';

type UserInfoProps = {
  user: UserInterface,
}

function UserInfo(props: UserInfoProps) {
  const { avatarUrl, name, isAdmin, profileUrl } = props.user;

  return (
    <div className="user-info">
      <img
        src={avatarUrl}
        alt={name}
        className="user-info__avatar"
      />
      <span className="user-info__name">
        {name}
      </span>

      <div className="user-info__menu">
        <div className="user-info__menu__inner">
          {isAdmin &&
            <Link to="/admin/orders" className="user-info__menu__link user-info__menu__link--admin">
              Admin Dashboard
            </Link>
          }
          <Link to="/buy" className="user-info__menu__link">
            Cart
          </Link>
          <a href={profileUrl} target="_blank" rel="noopener noreferrer" className="user-info__menu__link">
            Go to Dribbble Account
          </a>
          <Link to="/" className="user-info__menu__link">
            Log Out
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;

