import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <p className="footer__credits">
        {`© Copyright ${new Date().getFullYear()} `}
        <a href="https://branberg.com" target="_blank" rel="noopener noreferrer">
          Branberg
        </a>
      </p>
    </footer>
  );
}

export default Footer;
