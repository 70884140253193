import React from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import ControlledSwitch from './ControlledSwitch';
import UITag from './UITag';
import { OrderInterface } from '../types';
import { getShotsByIDs } from '../helpers';

type OrderDetailsProps = {
  order: OrderInterface,
  handleShipToggle: any,
}

function OrderDetails(props: OrderDetailsProps) {
  const {
    createdAt,
    shippingAddress,
    isShipped,
    orderTotalCents,
    stripeOrderUrl,
    customer,
    shotIDs,
  } = props.order;
  const { name, avatarUrl, email } = customer;

  const shots = getShotsByIDs(shotIDs);

  return (
    <div className="order-details">
      <Link
        to={`/admin/orders/print/${props.order.id}`}
        className="order-details__print-pdf"
        target="_blank"
      >
        View Printable Page
      </Link>
      <div className="order-details__customer">
        <img className="order-details__customer__avatar" src={avatarUrl} alt={name} />
        <div className="order-details__customer__details">
          <p className="order-details__customer__name">
            {name}
          </p>
          <a
            className="order-details__customer__email"
            href={`malto:${email}`}
          >
            {email}
          </a>
        </div>
      </div>

      <div className="order-details__shots">
        {shots.map(shot => (
          <img
            key={shot.id}
            className="order-details__shot"
            src={shot.url}
            alt={shot.id}
          />
        ))}
      </div>

      <table className="order-details__meta">
        <tbody>
          <tr>
            <td>
              Order Date
            </td>
            <td>
              {moment(createdAt).format('lll')}
            </td>
          </tr>
          <tr>
            <td>
              Shipping Address
            </td>
            <td>
              {shippingAddress}
            </td>
          </tr>
          <tr>
            <td>
              Shipping Status
            </td>
            <td>
              <UITag type={isShipped ? undefined : "warning"}>
                {isShipped ? "Shipped" : "Not Shipped"}
              </UITag>
            </td>
          </tr>
          <tr>
            <td>
              Order Total
            </td>
            <td>
              ${orderTotalCents / 100}.00
            </td>
          </tr>
          <tr>
            <td>
              Stripe Order
            </td>
            <td>
              <a href={stripeOrderUrl}>
                View in Stripe
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="order-details__shipping">
        <h3>
          Order Shipped?
        </h3>
        <div className="order-details__ship-toggle">
          <ControlledSwitch
            checked={isShipped}
            onChange={props.handleShipToggle}
          />
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;

