import React from 'react';
import { Link } from "react-router-dom";
import MagnetImage from './MagnetImage';
import { MAX_CART_ITEMS } from '../constants';
import { DUMMY_USER } from '../data/generatedDummyUser';

const handpickedShots = [
  'fridgeworth-demo/shots/babe_1x_ebdcbj',
  'fridgeworth-demo/shots/dribbble_content_1x_yjvm2c',
  'fridgeworth-demo/shots/disguise_xgdhyn',
  'fridgeworth-demo/shots/fna_1x_gbtjom',
  'fridgeworth-demo/shots/jucy_lucy_1x_txee28',
  'fridgeworth-demo/shots/mn_1x_p8dct2',
  'fridgeworth-demo/shots/personal_logo_1x_iqkbtp',
  'fridgeworth-demo/shots/n64_controller_1x_h5vtrr',
  'fridgeworth-demo/shots/msp_dev_team_1x_cjlqxx',
  'fridgeworth-demo/shots/mug_1x_kqbty2',
  'fridgeworth-demo/shots/griz_mountain_1x_k9so6o',
  'fridgeworth-demo/shots/firwewalkie_1x_hn37ek',
];

const filteredShots = DUMMY_USER.shots.filter(userShot => {
  return handpickedShots.indexOf(userShot.id) > -1;
});

function Welcome() {
  return (
    <div className="welcome">
      <div className="welcome__inner">
        <div className="welcome__left">
          <div className="welcome__content">
            <h1 className="welcome__title">
              Fridgeworth
            </h1>
            <p className="welcome__description">
              {`Magnetize ${MAX_CART_ITEMS} of your dribble shots for $5.`}
            </p>
            <Link to="/buy" className="welcome__cta">
              Log In With Dribbble
            </Link>
          </div>
        </div>
        <div className="welcome__right">
          <div className="welcome__buttons">
            {filteredShots.map((shot, index) => (
              <div
                key={shot.id}
                className="welcome__button"
                data-button-index={index + 1}
              >
                <div className="welcome__button__inner">
                  <MagnetImage backgroundImage={shot.url} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
