import React from 'react';
import * as classNames from 'classnames';

type UITagProps = {
  type?: 'info' | 'warning' | 'error',
  children: React.ReactNode,
}

function UITag(props: UITagProps) {
  const classes = classNames(
    'ui-tag', {
      'ui-tag--info': props.type === 'info',
      'ui-tag--warning' : props.type === 'warning',
      'ui-tag--error' : props.type === 'error',
    }
  )
  return (
    <span className={classes}>
      {props.children}
    </span>
  );
};

export default UITag;
