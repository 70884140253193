export const DUMMY_USER = {
  name: "Mike Berg",
  avatarUrl: "https://res.cloudinary.com/samb/image/upload/v1582927271/fridgeworth-demo/avatars/avatar-mikberg_dpmzfu.jpg",
  profileUrl: "https://dribbble.com/mikberg",
  isAdmin: true,
  shots: [
    {
      id: "fridgeworth-demo/shots/10years_lzk0hv",
      url: "//res.cloudinary.com/samb/image/upload/v1582927215/fridgeworth-demo/shots/10years_lzk0hv.jpg",
    },
    {
      id: "fridgeworth-demo/shots/125_dv6thk",
      url: "//res.cloudinary.com/samb/image/upload/v1582927214/fridgeworth-demo/shots/125_dv6thk.png",
    },
    {
      id: "fridgeworth-demo/shots/20-years_tikj7h",
      url: "//res.cloudinary.com/samb/image/upload/v1582927215/fridgeworth-demo/shots/20-years_tikj7h.png",
    },
    {
      id: "fridgeworth-demo/shots/accrew_logo_1x_uduklc",
      url: "//res.cloudinary.com/samb/image/upload/v1582927211/fridgeworth-demo/shots/accrew_logo_1x_uduklc.png",
    },
    {
      id: "fridgeworth-demo/shots/anders_1x_znin1f",
      url: "//res.cloudinary.com/samb/image/upload/v1582927211/fridgeworth-demo/shots/anders_1x_znin1f.png",
    },
    {
      id: "fridgeworth-demo/shots/animatedversions_tn5sv7",
      url: "//res.cloudinary.com/samb/image/upload/v1582927211/fridgeworth-demo/shots/animatedversions_tn5sv7.gif",
    },
    {
      id: "fridgeworth-demo/shots/asfn_goobqj",
      url: "//res.cloudinary.com/samb/image/upload/v1582927210/fridgeworth-demo/shots/asfn_goobqj.jpg",
    },
    {
      id: "fridgeworth-demo/shots/babe_1x_ebdcbj",
      url: "//res.cloudinary.com/samb/image/upload/v1582927210/fridgeworth-demo/shots/babe_1x_ebdcbj.png",
    },
    {
      id: "fridgeworth-demo/shots/bb_cwpkdu",
      url: "//res.cloudinary.com/samb/image/upload/v1582927210/fridgeworth-demo/shots/bb_cwpkdu.jpg",
    },
    {
      id: "fridgeworth-demo/shots/berg_1x_oxqonm",
      url: "//res.cloudinary.com/samb/image/upload/v1582927210/fridgeworth-demo/shots/berg_1x_oxqonm.png",
    },
    {
      id: "fridgeworth-demo/shots/bf_lol_1x_xosv8o",
      url: "//res.cloudinary.com/samb/image/upload/v1582927210/fridgeworth-demo/shots/bf_lol_1x_xosv8o.png",
    },
    {
      id: "fridgeworth-demo/shots/bike_icons_1x_ngu7qf",
      url: "//res.cloudinary.com/samb/image/upload/v1582927210/fridgeworth-demo/shots/bike_icons_1x_ngu7qf.png",
    },
    {
      id: "fridgeworth-demo/shots/boardlove_1x_ziyfmr",
      url: "//res.cloudinary.com/samb/image/upload/v1582927211/fridgeworth-demo/shots/boardlove_1x_ziyfmr.png",
    },
    {
      id: "fridgeworth-demo/shots/boxxer_i9mi2c",
      url: "//res.cloudinary.com/samb/image/upload/v1582927210/fridgeworth-demo/shots/boxxer_i9mi2c.jpg",
    },
    {
      id: "fridgeworth-demo/shots/branberg_logo_1x_bl25ee",
      url: "//res.cloudinary.com/samb/image/upload/v1582927210/fridgeworth-demo/shots/branberg_logo_1x_bl25ee.png",
    },
    {
      id: "fridgeworth-demo/shots/brand_mn_1x_gglhof",
      url: "//res.cloudinary.com/samb/image/upload/v1582927210/fridgeworth-demo/shots/brand_mn_1x_gglhof.png",
    },
    {
      id: "fridgeworth-demo/shots/card_flip_1x_ng3pih",
      url: "//res.cloudinary.com/samb/image/upload/v1582927209/fridgeworth-demo/shots/card_flip_1x_ng3pih.jpg",
    },
    {
      id: "fridgeworth-demo/shots/catherine_smiles_1x_xiz1il",
      url: "//res.cloudinary.com/samb/image/upload/v1582927209/fridgeworth-demo/shots/catherine_smiles_1x_xiz1il.png",
    },
    {
      id: "fridgeworth-demo/shots/collab_1x_fu2kaw",
      url: "//res.cloudinary.com/samb/image/upload/v1582927209/fridgeworth-demo/shots/collab_1x_fu2kaw.png",
    },
    {
      id: "fridgeworth-demo/shots/collins_key__1x_edwgq4",
      url: "//res.cloudinary.com/samb/image/upload/v1582927209/fridgeworth-demo/shots/collins_key__1x_edwgq4.png",
    },
    {
      id: "fridgeworth-demo/shots/community_1x_vmjzu3",
      url: "//res.cloudinary.com/samb/image/upload/v1582927209/fridgeworth-demo/shots/community_1x_vmjzu3.png",
    },
    {
      id: "fridgeworth-demo/shots/dame_1x_oxfogg",
      url: "//res.cloudinary.com/samb/image/upload/v1582927209/fridgeworth-demo/shots/dame_1x_oxfogg.png",
    },
    {
      id: "fridgeworth-demo/shots/deathtoabiker_u5y0mi",
      url: "//res.cloudinary.com/samb/image/upload/v1582927209/fridgeworth-demo/shots/deathtoabiker_u5y0mi.png",
    },
    {
      id: "fridgeworth-demo/shots/design_and_dev_1x_r6zule",
      url: "//res.cloudinary.com/samb/image/upload/v1582927209/fridgeworth-demo/shots/design_and_dev_1x_r6zule.png",
    },
    {
      id: "fridgeworth-demo/shots/design_blender_1x_iiazx7",
      url: "//res.cloudinary.com/samb/image/upload/v1582927209/fridgeworth-demo/shots/design_blender_1x_iiazx7.gif",
    },
    {
      id: "fridgeworth-demo/shots/disguise_xgdhyn",
      url: "//res.cloudinary.com/samb/image/upload/v1582927208/fridgeworth-demo/shots/disguise_xgdhyn.png",
    },
    {
      id: "fridgeworth-demo/shots/dreamdrink_gif_1x_uskmos",
      url: "//res.cloudinary.com/samb/image/upload/v1582927208/fridgeworth-demo/shots/dreamdrink_gif_1x_uskmos.gif",
    },
    {
      id: "fridgeworth-demo/shots/dribbble_content_1x_yjvm2c",
      url: "//res.cloudinary.com/samb/image/upload/v1582927208/fridgeworth-demo/shots/dribbble_content_1x_yjvm2c.png",
    },
    {
      id: "fridgeworth-demo/shots/dribbble_still_gj0qus",
      url: "//res.cloudinary.com/samb/image/upload/v1582927208/fridgeworth-demo/shots/dribbble_still_gj0qus.gif",
    },
    {
      id: "fridgeworth-demo/shots/dudes_v6j7vs",
      url: "//res.cloudinary.com/samb/image/upload/v1582927209/fridgeworth-demo/shots/dudes_v6j7vs.png",
    },
    {
      id: "fridgeworth-demo/shots/ev_play_1x_eicpqt",
      url: "//res.cloudinary.com/samb/image/upload/v1582927208/fridgeworth-demo/shots/ev_play_1x_eicpqt.png",
    },
    {
      id: "fridgeworth-demo/shots/fill_me_up_1x_nbbwok",
      url: "//res.cloudinary.com/samb/image/upload/v1582927208/fridgeworth-demo/shots/fill_me_up_1x_nbbwok.png",
    },
    {
      id: "fridgeworth-demo/shots/fill_stroke_1x_cosyes",
      url: "//res.cloudinary.com/samb/image/upload/v1582927208/fridgeworth-demo/shots/fill_stroke_1x_cosyes.jpg",
    },
    {
      id: "fridgeworth-demo/shots/firwewalkie_1x_hn37ek",
      url: "//res.cloudinary.com/samb/image/upload/v1582927208/fridgeworth-demo/shots/firwewalkie_1x_hn37ek.png",
    },
    {
      id: "fridgeworth-demo/shots/fna_1x_gbtjom",
      url: "//res.cloudinary.com/samb/image/upload/v1582927208/fridgeworth-demo/shots/fna_1x_gbtjom.png",
    },
    {
      id: "fridgeworth-demo/shots/footloose_lboqez",
      url: "//res.cloudinary.com/samb/image/upload/v1582927207/fridgeworth-demo/shots/footloose_lboqez.png",
    },
    {
      id: "fridgeworth-demo/shots/fourem_vb5lf4",
      url: "//res.cloudinary.com/samb/image/upload/v1582927206/fridgeworth-demo/shots/fourem_vb5lf4.png",
    },
    {
      id: "fridgeworth-demo/shots/fr_1x_lg3wku",
      url: "//res.cloudinary.com/samb/image/upload/v1582927206/fridgeworth-demo/shots/fr_1x_lg3wku.jpg",
    },
    {
      id: "fridgeworth-demo/shots/fran_hauser_1x_bsq9vc",
      url: "//res.cloudinary.com/samb/image/upload/v1582927206/fridgeworth-demo/shots/fran_hauser_1x_bsq9vc.png",
    },
    {
      id: "fridgeworth-demo/shots/fresh_rinse_1x_ysxmwf",
      url: "//res.cloudinary.com/samb/image/upload/v1582927206/fridgeworth-demo/shots/fresh_rinse_1x_ysxmwf.png",
    },
    {
      id: "fridgeworth-demo/shots/fridgeworth_logo_1x_hnvm0x",
      url: "//res.cloudinary.com/samb/image/upload/v1582927206/fridgeworth-demo/shots/fridgeworth_logo_1x_hnvm0x.png",
    },
    {
      id: "fridgeworth-demo/shots/gametrailers_logo_1x_z17jsf",
      url: "//res.cloudinary.com/samb/image/upload/v1582927206/fridgeworth-demo/shots/gametrailers_logo_1x_z17jsf.jpg",
    },
    {
      id: "fridgeworth-demo/shots/gaslight_1x_k7hhne",
      url: "//res.cloudinary.com/samb/image/upload/v1582927206/fridgeworth-demo/shots/gaslight_1x_k7hhne.png",
    },
    {
      id: "fridgeworth-demo/shots/gopher_1x_acx85f",
      url: "//res.cloudinary.com/samb/image/upload/v1582927206/fridgeworth-demo/shots/gopher_1x_acx85f.png",
    },
    {
      id: "fridgeworth-demo/shots/gopher_football_1x_jexspa",
      url: "//res.cloudinary.com/samb/image/upload/v1582927206/fridgeworth-demo/shots/gopher_football_1x_jexspa.jpg",
    },
    {
      id: "fridgeworth-demo/shots/gopher_hockey_1x_xocm2s",
      url: "//res.cloudinary.com/samb/image/upload/v1582927206/fridgeworth-demo/shots/gopher_hockey_1x_xocm2s.png",
    },
    {
      id: "fridgeworth-demo/shots/gossamer_1x_sdjtgr",
      url: "//res.cloudinary.com/samb/image/upload/v1582927205/fridgeworth-demo/shots/gossamer_1x_sdjtgr.png",
    },
    {
      id: "fridgeworth-demo/shots/griz_mountain_1x_k9so6o",
      url: "//res.cloudinary.com/samb/image/upload/v1582927203/fridgeworth-demo/shots/griz_mountain_1x_k9so6o.png",
    },
    {
      id: "fridgeworth-demo/shots/ha_logo_1x_oi33ge",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/ha_logo_1x_oi33ge.jpg",
    },
    {
      id: "fridgeworth-demo/shots/headset_1x_ns8p9x",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/headset_1x_ns8p9x.png",
    },
    {
      id: "fridgeworth-demo/shots/helix_icon_1x_suq5k6",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/helix_icon_1x_suq5k6.png",
    },
    {
      id: "fridgeworth-demo/shots/hof_oq0xbl",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/hof_oq0xbl.png",
    },
    {
      id: "fridgeworth-demo/shots/icons_1x_meppyi",
      url: "//res.cloudinary.com/samb/image/upload/v1582927197/fridgeworth-demo/shots/icons_1x_meppyi.png",
    },
    {
      id: "fridgeworth-demo/shots/imran_chaudhri_1x_raeoyx",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/imran_chaudhri_1x_raeoyx.png",
    },
    {
      id: "fridgeworth-demo/shots/jack_conte_1x_ovbma9",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/jack_conte_1x_ovbma9.jpg",
    },
    {
      id: "fridgeworth-demo/shots/jog_your_memory_1x_pttqq7",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/jog_your_memory_1x_pttqq7.jpg",
    },
    {
      id: "fridgeworth-demo/shots/jucy_lucy_1x_txee28",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/jucy_lucy_1x_txee28.png",
    },
    {
      id: "fridgeworth-demo/shots/knowble_logo_1x_zeb2zu",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/knowble_logo_1x_zeb2zu.png",
    },
    {
      id: "fridgeworth-demo/shots/learning_the_hard_way_1x_itqyqo",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/learning_the_hard_way_1x_itqyqo.gif",
    },
    {
      id: "fridgeworth-demo/shots/left_brain_right_brain_1x_peiesl",
      url: "//res.cloudinary.com/samb/image/upload/v1582927202/fridgeworth-demo/shots/left_brain_right_brain_1x_peiesl.jpg",
    },
    {
      id: "fridgeworth-demo/shots/lifting_wcmotx",
      url: "//res.cloudinary.com/samb/image/upload/v1582927201/fridgeworth-demo/shots/lifting_wcmotx.png",
    },
    {
      id: "fridgeworth-demo/shots/link_1x_kdh0qk",
      url: "//res.cloudinary.com/samb/image/upload/v1582927201/fridgeworth-demo/shots/link_1x_kdh0qk.png",
    },
    {
      id: "fridgeworth-demo/shots/lion_idnd5x",
      url: "//res.cloudinary.com/samb/image/upload/v1582927201/fridgeworth-demo/shots/lion_idnd5x.png",
    },
    {
      id: "fridgeworth-demo/shots/little_tea_cup_1x_rmtvyn",
      url: "//res.cloudinary.com/samb/image/upload/v1582927201/fridgeworth-demo/shots/little_tea_cup_1x_rmtvyn.png",
    },
    {
      id: "fridgeworth-demo/shots/lone_star_1x_neaekf",
      url: "//res.cloudinary.com/samb/image/upload/v1582927201/fridgeworth-demo/shots/lone_star_1x_neaekf.png",
    },
    {
      id: "fridgeworth-demo/shots/loveboard_1x_n4i9sp",
      url: "//res.cloudinary.com/samb/image/upload/v1582927201/fridgeworth-demo/shots/loveboard_1x_n4i9sp.png",
    },
    {
      id: "fridgeworth-demo/shots/lucky_penny_1x_swixe4",
      url: "//res.cloudinary.com/samb/image/upload/v1582927201/fridgeworth-demo/shots/lucky_penny_1x_swixe4.png",
    },
    {
      id: "fridgeworth-demo/shots/make_waves_1x_trxfos",
      url: "//res.cloudinary.com/samb/image/upload/v1582927201/fridgeworth-demo/shots/make_waves_1x_trxfos.png",
    },
    {
      id: "fridgeworth-demo/shots/media-specialist_1x_njqjcg",
      url: "//res.cloudinary.com/samb/image/upload/v1582927201/fridgeworth-demo/shots/media-specialist_1x_njqjcg.png",
    },
    {
      id: "fridgeworth-demo/shots/minnesota_union_1x_kdbvqs",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/minnesota_union_1x_kdbvqs.png",
    },
    {
      id: "fridgeworth-demo/shots/minnesotaball-shirt_qaofkj",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/minnesotaball-shirt_qaofkj.png",
    },
    {
      id: "fridgeworth-demo/shots/minnie_v_paul_final_1x_ijweko",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/minnie_v_paul_final_1x_ijweko.png",
    },
    {
      id: "fridgeworth-demo/shots/mn_1x_p8dct2",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/mn_1x_p8dct2.png",
    },
    {
      id: "fridgeworth-demo/shots/mn_badge_1x_srm76q",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/mn_badge_1x_srm76q.png",
    },
    {
      id: "fridgeworth-demo/shots/mn_bball_1x_c5pdkp",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/mn_bball_1x_c5pdkp.png",
    },
    {
      id: "fridgeworth-demo/shots/mn_pennant_1x_nzyisp",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/mn_pennant_1x_nzyisp.png",
    },
    {
      id: "fridgeworth-demo/shots/mr_batter_1x_rfbujv",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/mr_batter_1x_rfbujv.png",
    },
    {
      id: "fridgeworth-demo/shots/msp_dev_team_1x_cjlqxx",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/msp_dev_team_1x_cjlqxx.png",
    },
    {
      id: "fridgeworth-demo/shots/mug_1x_kqbty2",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/mug_1x_kqbty2.png",
    },
    {
      id: "fridgeworth-demo/shots/mv_1x_x0q9mb",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/mv_1x_x0q9mb.png",
    },
    {
      id: "fridgeworth-demo/shots/n64_controller_1x_h5vtrr",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/n64_controller_1x_h5vtrr.png",
    },
    {
      id: "fridgeworth-demo/shots/new_alliance_logo_1x_cojvr3",
      url: "//res.cloudinary.com/samb/image/upload/v1582927200/fridgeworth-demo/shots/new_alliance_logo_1x_cojvr3.png",
    },
    {
      id: "fridgeworth-demo/shots/nicole_eagan_1x_ccatou",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/nicole_eagan_1x_ccatou.png",
    },
    {
      id: "fridgeworth-demo/shots/no_place_like_home_1x_uks4br",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/no_place_like_home_1x_uks4br.png",
    },
    {
      id: "fridgeworth-demo/shots/northstar_1x_p7ijh5",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/northstar_1x_p7ijh5.png",
    },
    {
      id: "fridgeworth-demo/shots/ntp_1x_ezyqgb",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/ntp_1x_ezyqgb.png",
    },
    {
      id: "fridgeworth-demo/shots/numnber_01_1x_nac3eo",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/numnber_01_1x_nac3eo.png",
    },
    {
      id: "fridgeworth-demo/shots/ota_mark_1x_dbsd65",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/ota_mark_1x_dbsd65.png",
    },
    {
      id: "fridgeworth-demo/shots/pencil_icon_1x_wnv2za",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/pencil_icon_1x_wnv2za.png",
    },
    {
      id: "fridgeworth-demo/shots/persona_logo_1x_ovjumq",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/persona_logo_1x_ovjumq.png",
    },
    {
      id: "fridgeworth-demo/shots/personal_logo_1x_iqkbtp",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/personal_logo_1x_iqkbtp.png",
    },
    {
      id: "fridgeworth-demo/shots/private_reserve_logo_1x_b5wv13",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/private_reserve_logo_1x_b5wv13.png",
    },
    {
      id: "fridgeworth-demo/shots/rl_logo_1x_qitpwm",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/rl_logo_1x_qitpwm.png",
    },
    {
      id: "fridgeworth-demo/shots/robot_meet_human_1x_uzjrv1",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/robot_meet_human_1x_uzjrv1.png",
    },
    {
      id: "fridgeworth-demo/shots/rubio_hb9aot",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/rubio_hb9aot.jpg",
    },
    {
      id: "fridgeworth-demo/shots/run_1x_jcfree",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/run_1x_jcfree.jpg",
    },
    {
      id: "fridgeworth-demo/shots/run_kick_1x_z82w15",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/run_kick_1x_z82w15.png",
    },
    {
      id: "fridgeworth-demo/shots/run_mn_1x_vgpuyt",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/run_mn_1x_vgpuyt.png",
    },
    {
      id: "fridgeworth-demo/shots/sab_e0qodf",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/sab_e0qodf.png",
    },
    {
      id: "fridgeworth-demo/shots/sanwise_1x_mcz459",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/sanwise_1x_mcz459.png",
    },
    {
      id: "fridgeworth-demo/shots/screenshare_1x_geqlwy",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/screenshare_1x_geqlwy.png",
    },
    {
      id: "fridgeworth-demo/shots/shattered_1x_o5ho2q",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/shattered_1x_o5ho2q.png",
    },
    {
      id: "fridgeworth-demo/shots/sig_zlvnh8",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/sig_zlvnh8.png",
    },
    {
      id: "fridgeworth-demo/shots/ski_uevqb9",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/ski_uevqb9.png",
    },
    {
      id: "fridgeworth-demo/shots/ski-u-mah_edsokh",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/ski-u-mah_edsokh.jpg",
    },
    {
      id: "fridgeworth-demo/shots/socialize_1x_bvy1ys",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/socialize_1x_bvy1ys.png",
    },
    {
      id: "fridgeworth-demo/shots/speak_logo_1x_nds2wc",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/speak_logo_1x_nds2wc.png",
    },
    {
      id: "fridgeworth-demo/shots/spotify_dribbble_1x_dqs7bc",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/spotify_dribbble_1x_dqs7bc.jpg",
    },
    {
      id: "fridgeworth-demo/shots/switch_logo_1x_yikujv",
      url: "//res.cloudinary.com/samb/image/upload/v1582927197/fridgeworth-demo/shots/switch_logo_1x_yikujv.png",
    },
    {
      id: "fridgeworth-demo/shots/tennis_is_life_1x_kcsrq2",
      url: "//res.cloudinary.com/samb/image/upload/v1582927197/fridgeworth-demo/shots/tennis_is_life_1x_kcsrq2.png",
    },
    {
      id: "fridgeworth-demo/shots/twinkies_1x_xkalyd",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/twinkies_1x_xkalyd.png",
    },
    {
      id: "fridgeworth-demo/shots/uncommon_ramen_1x_axv0dh",
      url: "//res.cloudinary.com/samb/image/upload/v1582927199/fridgeworth-demo/shots/uncommon_ramen_1x_axv0dh.png",
    },
    {
      id: "fridgeworth-demo/shots/upcoffee_rr5x4d",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/upcoffee_rr5x4d.png",
    },
    {
      id: "fridgeworth-demo/shots/wereopen_wrbide",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/wereopen_wrbide.png",
    },
    {
      id: "fridgeworth-demo/shots/yonas_wrnamv",
      url: "//res.cloudinary.com/samb/image/upload/v1582927198/fridgeworth-demo/shots/yonas_wrnamv.png",
    },
    {
      id: "fridgeworth-demo/shots/yuma_desert_runners_1x_uxcvdm",
      url: "//res.cloudinary.com/samb/image/upload/v1582927197/fridgeworth-demo/shots/yuma_desert_runners_1x_uxcvdm.png",
    },
  ],
}
