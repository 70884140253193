import { DUMMY_USER } from './data/generatedDummyUser';
import { DUMMY_ORDERS } from './data/generatedDummyOrders';
import { ShotInterface } from './types';

export function getShotsByIDs(ids: string[]) {
  let newShots: ShotInterface[] = [];

  ids.forEach(id => {
    newShots.push(DUMMY_USER.shots.filter(shot => shot.id === id)[0]);
  });

  return newShots;
}

export function getOrderByID(id: number | undefined) {
  if (id === undefined) {
    console.log('ID is undefined');
    return null;
  }

  return DUMMY_ORDERS.filter(order => order.id === id)[0];
}
