import React from 'react';
import { useParams, Link } from "react-router-dom";
import { getOrderByID } from '../helpers';
import { getShotsByIDs } from '../helpers';

interface RouteParams {
  id: string
}

function OrderPrint() {
  const { id } = useParams<RouteParams>();
  const order = getOrderByID(parseInt(id));

  if (order === null || order === undefined) {
    return (
      <div>
        {`Order ID #${id} not found...`}
        <br />
        <Link to="/admin/orders" className="button button--pink">
          View All Orders
        </Link>
      </div>
    );
  }

  const shots = getShotsByIDs(order.shotIDs);

  return (
    <div className="printable-order">
      <div className="printable-order__shots">
        {shots.map(shot => (
          <img
            key={shot.id}
            src={shot.url}
            alt={shot.id}
            className="printable-order__shot"
          />
        ))}
      </div>
    </div>
  );
}

export default OrderPrint;
