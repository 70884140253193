import React from 'react';
import { Link } from "react-router-dom";
import Logo from './Logo';
import UserInfo from './UserInfo';
import { UserInterface } from '../types';

type HeaderProps = {
  user: UserInterface,
  children?: React.ReactNode,
}

function Header(props: HeaderProps) {
  return (
    <header className="header">
      <div className="header__logo">
        <Link to="/" className="header__logo__inner">
          <Logo />
        </Link>
      </div>
      {props.children &&
        <div className="header__content">
          {props.children}
        </div>
      }
      <div className="header__user">
        <UserInfo user={props.user} />
      </div>
    </header>
  );
}

export default Header;

