import React from 'react';

type MagnetImageProps = {
  backgroundImage: string,
}

function MagnetImage(props: MagnetImageProps) {
  return (
    <span
      className="magnet-image"
      style={{ backgroundImage: `url(${props.backgroundImage})` }}
    />
  );
}

export default MagnetImage;
