import React from 'react';
import moment from 'moment';
import * as classNames from 'classnames';
import Header from './Header';
import Modal from './Modal';
import OrderDetails from './OrderDetails';
import UITag from './UITag';
import { DUMMY_USER } from '../data/generatedDummyUser';
import { DUMMY_ORDERS } from '../data/generatedDummyOrders';
import { OrderInterface } from '../types';

type OrdersState = {
  orderModalIsOpen: boolean,
  activeOrder: OrderInterface,
  orders: OrderInterface[],
}

class Orders extends React.Component<{}, OrdersState> {
  constructor(props: OrdersState) {
    super(props);

    this.state = {
      orderModalIsOpen: false,
      activeOrder: DUMMY_ORDERS[0],
      orders: [],
    }

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleShipToggle = this.handleShipToggle.bind(this);
  }

  componentDidMount() {
    this.setState({
      orders: DUMMY_ORDERS,
    });
  }

  openModal(order: OrderInterface) {
    this.setState({
      orderModalIsOpen: true,
      activeOrder: order,
    });
  }

  closeModal() {
    this.setState({
      orderModalIsOpen: false,
    });
  }

  handleShipToggle(event: any) {
    let newOrderData = [...this.state.orders];

    // get index of changed value
    const index = newOrderData.findIndex(x => x.id === this.state.activeOrder.id);

    // modify the index
    newOrderData[index].isShipped = !newOrderData[index].isShipped;

    // save it as new state
    this.setState({
      orders: newOrderData
    });
  }

  renderOrderModal() {
    if (!this.state.orderModalIsOpen) {
      return null;
    }

    return (
      <Modal
        closeModal={this.closeModal}
        title="Manage Order"
        actionLabel="Save Order"
      >
        <OrderDetails
          order={this.state.activeOrder}
          handleShipToggle={this.handleShipToggle}
        />
      </Modal>
    );
  }

  render() {
    return (
      <>
        <div className="app">
          <div className="app__header">
            <Header user={DUMMY_USER} />
          </div>
          <div className="app__body">
            <div className="page">
              <h2 className="page__title">
                Orders
              </h2>
              <table className="orders-table">
                <thead className="orders-table__header">
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Ship Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.orders.map(order => (
                    <tr
                      key={order.id}
                      onClick={() => this.openModal(order)}
                      className={classNames('orders-table__row', { 'orders-table__row--shipped': order.isShipped })}
                    >
                      <td>{order.id}</td>
                      <td>{moment(order.createdAt).format('MM/DD/YY')}</td>
                      <td>{order.customer.name}</td>
                      <td>{order.customer.email}</td>
                      <td>
                        <UITag type={order.isShipped ? undefined : 'warning'}>
                          {order.isShipped ? 'Shipped' : 'Not Shipped'}
                        </UITag>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.renderOrderModal()}
      </>
    );
  }
}

export default Orders;
