import React from 'react';

type ControlledSwitchProps = {
  checked: boolean,
  onChange: any,
  label?: string,
}

function ControlledSwitch(props: ControlledSwitchProps) {
  return (
    <div className="switch-ui">
      <input
        type="checkbox"
        id="ship-toggle"
        checked={props.checked}
        onChange={props.onChange}
      />
      <label htmlFor="ship-toggle">
        {props.label}
      </label>
    </div>
  );
};

export default ControlledSwitch;
