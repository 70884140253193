import React from 'react';
import * as classNames from 'classnames';
import MagnetImage from './MagnetImage';

type MagnetCheckboxProps = {
  backgroundImage: string;
  id: string;
  onChange: any;
  isChecked?: any;
  isDisabled?: any;
}

function MagnetCheckbox(props: MagnetCheckboxProps) {
  const {
    backgroundImage,
    id,
    onChange,
    isChecked,
    isDisabled,
  } = props;

  const classes = classNames(
    'magnet-checkbox',
    { 'magnet-checkbox--selected': isChecked },
  );

  return (
    <div className={classes}>
      <input
        id={`shot-${id.toString()}`}
        value={id}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
      />
      <label
        htmlFor={`shot-${id.toString()}`}
        className="magnet-checkbox__inner"
      >
        <MagnetImage backgroundImage={backgroundImage} />
        <span className="magnet-checkbox__icon" />
      </label>
    </div>
  );
}

export default MagnetCheckbox;
