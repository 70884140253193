import React from 'react';

type ModalProps = {
  title?: string,
  closeModal: any,
  children?: React.ReactNode,
  actionLabel?: string
}

function Modal(props: ModalProps) {
  return (
    <div className="modal">
      <div className="modal__inner">
        <div className="modal__header">
          {props.title &&
            <h3 className="modal__title">
              {props.title}
            </h3>
          }
          <button
            className="modal__close"
            onClick={props.closeModal}
            type="button"
          />
        </div>
        <div className="modal__content">
          {props.children}
        </div>
        <div className="modal__footer">
          <button
            className="modal__primay-action"
            type="button"
            onClick={props.closeModal}
          >
            {props.actionLabel ? props.actionLabel : "Save Changes"}
          </button>
          <button
            className="modal__cancel-action"
            type="button"
            onClick={props.closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;

