import React from 'react';
import MagnetImage from './MagnetImage';
import { MAX_CART_ITEMS } from '../constants';
import { ShotInterface } from '../types';

type CartProps = {
  items: ShotInterface[],
  onCartSubmit: any,
  cartIsFull: boolean,
  removeShotFromCart: any,
}

function EmptySlot(props: any) {
  return (
    <div className="cart__item">
      <span className="cart__item__count">
        {props.itemNumber}
      </span>
    </div>
  );
}

function renderEmptySlots(items: any) {
  let emptySlots = [];
  let i = items.length;

  while (i < MAX_CART_ITEMS) {
    emptySlots.push(<EmptySlot key={i} itemNumber={i + 1} />);

    i++;
  }

  return emptySlots;
}

function Cart(props: CartProps) {
  return (
    <div className="cart">
      {props.items.map((shot: ShotInterface) => (
        <button
          key={shot.id}
          className="cart__item cart__item--filled"
          type="button"
          onClick={() => props.removeShotFromCart(shot)}
        >
          <MagnetImage backgroundImage={shot.url} />
        </button>
      ))}
      {renderEmptySlots(props.items)}
      <form onSubmit={props.onCartSubmit}>
        <button
          id="checkout-button"
          className="cart__checkout"
          disabled={!props.cartIsFull}
        >
          Checkout
        </button>
      </form>
    </div>
  );
}

export default Cart;

