import React from 'react';
import * as classNames from 'classnames';
import MagnetCheckbox from './MagnetCheckbox';
import MagnetImage from './MagnetImage';
import Header from './Header';
import Footer from './Footer';
import Cart from './Cart';
import Modal from './Modal';
import { MAX_CART_ITEMS, SHOTS_PER_PAGE, FAKE_LOAD_TIMEOUT } from '../constants';
import { ShotInterface, UserInterface } from '../types';
import { DUMMY_USER } from '../data/generatedDummyUser';

type PickerState = {
  user: UserInterface,
  cartItems: ShotInterface[],
  checkoutModalIsOpen: boolean,
  loadedShots: ShotInterface[],
  allShotsAreLoaded: boolean,
  moreShotsLoading: boolean,
}

class Picker extends React.Component<{}, PickerState> {
  constructor(props: PickerState) {
    super(props);

    this.state = {
      user: DUMMY_USER,
      cartItems: [],
      checkoutModalIsOpen: false,
      loadedShots: [],
      allShotsAreLoaded: false,
      moreShotsLoading: false,
    }

    this.onMagnetCheckboxChange = this.onMagnetCheckboxChange.bind(this);
    this.onCartSubmit = this.onCartSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.loadMoreShots = this.loadMoreShots.bind(this);
    this.removeShotFromCart = this.removeShotFromCart.bind(this);
  }

  componentDidMount() {
    // fake our initial load
    const initialShots = this.state.user.shots.slice(0, SHOTS_PER_PAGE);

    this.setState({
      loadedShots: initialShots,
    });
  }

  openModal() {
    this.setState({
      checkoutModalIsOpen: true,
    });
  }

  closeModal() {
    this.setState({
      checkoutModalIsOpen: false,
    });
  }

  cartIsFull() {
    return this.state.cartItems.length === MAX_CART_ITEMS;
  }

  addShotToCart(shot: ShotInterface) {
    if (this.cartIsFull()) {
      return null;
    }

    this.setState({
      cartItems: [...this.state.cartItems, shot],
    });
  }

  removeShotFromCart(shot: ShotInterface) {
    const items = this.state.cartItems.filter(item => item.id !== shot.id);

    this.setState({
      cartItems: items,
    });
  }

  onMagnetCheckboxChange(shot: ShotInterface) {
    if (this.isChecked(shot)) {
      this.removeShotFromCart(shot);
    } else {
      this.addShotToCart(shot);
    }
  }

  onCartSubmit(event: any) {
    event.preventDefault();

    this.openModal();
  }

  isChecked(shot: ShotInterface) {
    return this.state.cartItems.filter(item => item.id === shot.id ).length > 0;
  }

  isDisabled(shot: ShotInterface) {
    if (this.cartIsFull() && !this.isChecked(shot)) {
      return true;
    }

    return false;
  }

  loadMoreShots() {
    const currentShotCount = this.state.loadedShots.length;
    const allShotCount = this.state.user.shots.length;

    if (currentShotCount === allShotCount) {
      this.setState({
        allShotsAreLoaded: true,
      });
    }

    const newShots = this.state.user.shots.slice(0, SHOTS_PER_PAGE + currentShotCount);

    this.setState({
      moreShotsLoading: true,
    });

    setTimeout(() => {
      this.setState({
        loadedShots: newShots,
        moreShotsLoading: false,
      });
    }, FAKE_LOAD_TIMEOUT);
  }

  renderCartItems() {
    return this.state.cartItems.map((shot: ShotInterface) => (
      <div
        key={shot.id}
        className="cart__item"
      >
        <MagnetImage backgroundImage={shot.url} />
      </div>
    ));
  }

  renderPageTitle() {
    const count = this.state.cartItems.length;
    const max = MAX_CART_ITEMS;

    switch(count) {
      case 0:
        return `Choose ${max} shots`;
      case max:
        return 'All done, proceed to checkout...';
      case max - 1:
        return 'Choose 1 more shot';
      default:
        return `Choose ${max - count} more shots`;
    }
  }

  renderCheckoutModal() {
    if (!this.state.checkoutModalIsOpen) {
      return null;
    }

    return (
      <Modal
        title="Heads up..."
        closeModal={this.closeModal}
      >
        This site is for demo purposes only, if it were real, you'd enter your payment and shipping information here...
      </Modal>
    );
  }

  render() {
    const classes = classNames(
      'app',
      { 'app--modal-open': this.state.checkoutModalIsOpen }
    );
    return (
      <>
        <div className={classes}>
          <div className="app__header">
            <Header user={this.state.user}>
              <Cart
                items={this.state.cartItems}
                cartIsFull={this.cartIsFull()}
                onCartSubmit={this.onCartSubmit}
                removeShotFromCart={this.removeShotFromCart}
              />
            </Header>
          </div>
          <div className="app__body">
            <div className="page">
              <h2 className="page__title">
                {this.renderPageTitle()}
              </h2>
              <div className="grid">
                {this.state.loadedShots.map((shot: ShotInterface) => (
                  <div className="grid__item" key={shot.id}>
                    <MagnetCheckbox
                      id={shot.id}
                      backgroundImage={shot.url}
                      onChange={() => this.onMagnetCheckboxChange(shot)}
                      isChecked={this.isChecked(shot)}
                      isDisabled={this.isDisabled(shot)}
                    />
                  </div>
                ))}
              </div>
              {!this.state.allShotsAreLoaded &&
                <div className="load-more">
                  <button
                    className="load-more__button"
                    type="button"
                    onClick={this.loadMoreShots}
                    disabled={this.state.moreShotsLoading}
                  >
                    {this.state.moreShotsLoading ? 'Loading...' : 'Load More'}
                  </button>
                </div>
              }
            </div>
            <Footer />
          </div>
        </div>
        {this.renderCheckoutModal()}
      </>
    );
  }
}

export default Picker;
